import "../../../assets/css/tabs/documentCompliance.css"

import clientIcon from "../../../assets/images/inventory.svg"

import React, { useEffect, useRef, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import DocumentInformationModal from "./documentInformationModal";
import { validateDocument, legalDocumentIDs } from "./utils";
import { addAction } from "../../../utilities/utils";
import monitorJobStatus from "../../../utilities/monitorJobStatus.js";

const URI_SUPPLIER_DOC_COMPLIANCE = process.env.REACT_APP_URI_SUPPLIER_DOC_COMPLIANCE

export default function DocumentComplianceTable(props) {
	// VARIABLES RECIEVED FROM PROPS
	const { supplier, setSupplier, serverDate, handleSaveChanges, complianceSetup, userLogged } = props

	// VARIABLES TO CONTROL YEARS TO SHOW, DOCUMENT NAMES, MONTHS, DATA AND MONTHLY COMPLIANCE PERCENT
	const [yearsToShow, setYearsToShow] = useState([])
	const [monthlyCompliancePercent, setMonthlyCompliancePercent] = useState([])
	const [validateDocuments, setValidateDocuments] = useState([])

	// LEGAL DOCUMENTS
	const [documentReference, setDocumentReference] = useState([])
	const [documentComplianceList, setDocumentComplianceList] = useState([])
	const [futureDocuments, setFutureDocuments] = useState([])

	// CLIENT DOCUMENTS
	const [clientDocumentReference, setClientDocumentReference] = useState([])
	const [clientDocumentComplianceList, setClientDocumentComplianceList] = useState([])
	const [clientFutureDocuments, setClientFutureDocuments] = useState([])

	// VARIABLE TO CONTROL THE SELECTED YEAR TO SHOW
	const [selectedYear, setSelectedYear] = useState(null)

	// VARIABLE TO CONTROL THE SELECTED CLIENT TO SHOW
	const [selectedClientID, setSelectedClientID] = useState(null)

	// VARIABLE TO CONTROL THE DOCUMENT INFORMATION MODAL
	const [showDocumentModal, setShowDocumentModal] = useState(null)
	const [modalData, setModalData] = useState(null)

	// REFERENCE TO KNOW WICH SQUARE IS SELECTED
	const docSquareRef = useRef(null)

	useEffect(() => {
		document.body.addEventListener("click", unselectDocSquare)

		return function cleanUp() {
			document.body.removeEventListener("click", unselectDocSquare)
		}
	}, [])

	useEffect(() => {
		getSupplierDocuments(supplier._id)
	}, [supplier])

	// FUNCTION TO UNSELECT THE SQUARE DOC
	const unselectDocSquare = (e) => {
		const elements = Array.from(document.body.getElementsByClassName("squareContainer"))
		elements.forEach(element => element.classList.remove("active"))

		if (docSquareRef.current && docSquareRef.current.contains(e.target)) {
			docSquareRef.current.parentElement.classList.toggle("active")
		}
	}

	// FUNTION TO GET THE DOCUMENT COMPLIANCE OF THE SUPPLIER
	const getSupplierDocuments = async (id) => {
		try {
			const { data } = await axios.post(URI_SUPPLIER_DOC_COMPLIANCE + id, {
				userRol: userLogged.rol,
				userDataID: userLogged.dataID,
			})

			if (data.errorMessage) {
				return Swal.fire({
					icon: 'error',
					text: data.errorMessage,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}

			setYearsToShow(data.yearsToShow)
			setSelectedYear(selectedYear ?? data.yearsToShow[0])
			setSelectedClientID(data.documentReference.clientDocumentsToShow[0].entityID)

			setMonthlyCompliancePercent(data.monthlyCompliancePercent)
			setValidateDocuments(data.validateDocuments)

			setDocumentReference(data.documentReference.legalDocumentsToShow)
			setDocumentComplianceList(data.documentComplianceList.legalSupplierDocuments)
			setFutureDocuments(data.futureDocuments.legalFutureDocuments)

			setClientDocumentReference(data.documentReference.clientDocumentsToShow)
			setClientDocumentComplianceList(data.documentComplianceList.clients)
			setClientFutureDocuments(data.futureDocuments.clientFutureDocuments)
		} catch (error) {
			Swal.fire({
				icon: 'error',
				text: 'Failed to get document compliance from supplier',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			console.log("Error al obtener documentos del supplier: " + error.message)
		}
	}

	//FUNCTION TO OPEN THE MODAL FOR THE SQUARE DOC SELECTED
	const handleOpenDocumentModal = (target, documentReference, documentData, newOnceDocument, clientDocument) => {
		docSquareRef.current = target
		setModalData({
			documentReference: documentReference,
			documentData: documentData,
			newOnceDocument: newOnceDocument,
			clientDocument: clientDocument,
		})
		setShowDocumentModal(true)
	}

	// FUNCTION TO GET THE DOC SQUARE JSX
	const handleGetDocumentSquare = (documentReference, monthRecived, clientDocument) => {
		let documentFound, futureDocument

		if (!clientDocument) {
			// VERIFY EXISTING DOCUMENT
			documentFound = documentComplianceList.filter(document =>
				document.yearCreatedAt === selectedYear).find(document =>
					documentReference._id.toString() === document.documentID.toString() && monthRecived === document.monthCreatedAt)

			// VERIFY FUTURE DOCUMENT
			futureDocument = futureDocuments.filter(document =>
				document.yearCreatedAt === selectedYear).find(document =>
					documentReference._id.toString() === document.documentID.toString() && monthRecived === document.monthCreatedAt)
		} else {
			// VERIFY EXISTING CLIENT DOCUMENT
			documentFound = clientDocumentComplianceList.find(client =>
				client.clientID.toString() === selectedClientID.toString()).clientSupplierDocuments.filter(document =>
					document.yearCreatedAt === selectedYear).find(document =>
						documentReference._id.toString() === document.documentID.toString() && monthRecived === document.monthCreatedAt)


			// VERIFY FUTURE CLIENT DOCUMENT
			futureDocument = clientFutureDocuments.find(client =>
				client.entityID.toString() === selectedClientID.toString()).futureDocuments.filter(document =>
					document.yearCreatedAt === selectedYear).find(document =>
						documentReference._id.toString() === document.documentID.toString() && monthRecived === document.monthCreatedAt)
		}

		const docIsRepse = documentReference._id.toString() === legalDocumentIDs.repseFisica || documentReference._id.toString() === legalDocumentIDs.repseMoral
		const docIsActaOriginal = documentReference._id.toString() === legalDocumentIDs.actaConstitutivaOriginalMoral
		const docIsIDRepresentanteLegal = documentReference._id.toString() === legalDocumentIDs.idRepresentanteLegalMoral

		const docDueDate = docIsRepse ? "36 Months" :
			docIsIDRepresentanteLegal ? "12 Months" : "Once"

		// TEMPORAL OBJECT TO CHANGE A "ONCE" DOCUMENT
		const temporalDocument = {
			documentID: documentReference._id,
			documentName: documentReference.documentName,
			document: null,
			uploadedAt: null,
			comment: null,
			compliant: false,
			yearCreatedAt: serverDate.year,
			monthCreatedAt: serverDate.month,
			dayCreatedAt: serverDate.day,
			validated: false,
			dueDate: docDueDate,
			noActions: false,
		}

		const reviewRequestIcon = documentFound?.reviewRequest ? "reviewRequestIcon" : ""

		if (documentFound) {
			return (
				<OverlayTrigger
					overlay={
						<Tooltip id={`tooltipDocumentName`} style={{ position: "fixed" }}>
							{documentFound.compliant ? "Compliant" :
								documentFound.reviewRequest ? "Review requested" :
								documentFound.document ? "Pending Review" : "Non-Compliant"
							}
							<br />
							Last Uploaded: {documentFound.uploadedAt ? documentFound.uploadedAt : "N/A"}
						</Tooltip>
					}>
					<div className={`documentSquare ${documentFound.documentStatus} ${reviewRequestIcon}`} onClick={(e) => handleOpenDocumentModal(e.target, documentReference, documentFound, false, clientDocument)} />
				</OverlayTrigger>
			)
		} else if (futureDocument) {
			return (
				<OverlayTrigger
					overlay={
						<Tooltip id={`tooltipDocumentName`} style={{ position: "fixed" }}>
							Future Pending Document
						</Tooltip>
					}>
					<div className={`documentSquare ${futureDocument.documentStatus}`} />
				</OverlayTrigger>
			)
		} else if (monthRecived === serverDate.month && (documentReference.periodicity === 0 || docIsRepse || docIsIDRepresentanteLegal) && !docIsActaOriginal) {
			// } else if (monthRecived === serverDate.month && (documentReference.periodicity === 0 || docIsRepse) && !docIsActaOriginal) {
			return (
				<OverlayTrigger
					overlay={
						<Tooltip id={`tooltipDocumentName`} style={{ position: "fixed" }}>
							You can update this document if necessary
						</Tooltip>
					}>
					<div className={`documentSquare noDocumentNeeded`} onClick={(e) => handleOpenDocumentModal(e.target, documentReference, temporalDocument, true, clientDocument)} />
				</OverlayTrigger>
			)
		} else {
			return (
				<OverlayTrigger
					overlay={
						<Tooltip id={`tooltipDocumentName`} style={{ position: "fixed" }}>
							No actions Needed
						</Tooltip>
					}>
					<div className={`documentSquare noDocumentNeeded`} />
				</OverlayTrigger>
			)
		}
	}

	// FUNCTION TO SAVE THE DOCUMENT INFORMATION
	const changeDocumentInformation = async (docUniqueId, property, newValue, clientDocument) => {
		return new Promise(async resolve => {
			Swal.fire({
				footer: 'Loading, please wait',
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				didOpen: () => {
					Swal.showLoading()
				},
			})
	
			let sendKey = false
	
			const updateDocumentComplianceList = !clientDocument
				? JSON.parse(JSON.stringify(documentComplianceList))
				: JSON.parse(JSON.stringify(clientDocumentComplianceList.find(client =>
					client.clientID.toString() === selectedClientID.toString()).clientSupplierDocuments))
			let updateSupplier = JSON.parse(JSON.stringify(supplier))
	
			let document = updateDocumentComplianceList.find(doc => doc._id.toString() === docUniqueId.toString())
	
			document[property] = newValue
	
			// LOGIC TO RUN IF THE SUPPLIER CHANGE THE NO ACTION PROPERTY
			if (property === "noActions") {
				document.compliant = newValue
			}
	
			if (property === "compliant" && newValue) {
				document.reviewRequest = false
			}
	
			// CREATE ACTION FOR DOCUMENT UPLOADED (NOT AUTO VALIDATED)
			if (newValue && property === "document" && !validateDocuments.includes(document.documentID)) {
				document.reviewRequest = false
	
				addAction("Document uploaded", updateSupplier._id, document.documentID, docUniqueId)
			}
	
			// LOGIC TO RUN IF THE SUPPLIER UPLOAD A DOCUMENT THAT NEEDS VALIDATION
			if (newValue && property === "document" && validateDocuments.includes(document.documentID)) {
				const data = await validateDocument(
					document,
					updateSupplier._id,
					updateSupplier.informationCompliance.generalInformation.companyName,
					updateSupplier.informationCompliance.generalInformation.rfc)
	
				const message = data?.message
	
				if (data?.errorMessage) {
					document.validated = false;
					document.compliant = false;
					document.document = "";
	
					console.log("entra aqui")
	
					return Swal.fire({
						icon: 'error',
						text: `${data?.errorMessage}`,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						didOpen: () => {
							Swal.hideLoading()
						}
					})
				}
	
				if (message) {
					Swal.fire({
						text: message,
						showConfirmButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading(); // Muestra el icono de carga
						},
					});
	
					// Si el tipo de documento es "SUA" y se recibe jobId, empezar el monitorJobStatus
					const jobId = data?.jobId;
					if (jobId) {
						const docCompliance = true;
						const job = await monitorJobStatus(jobId, docCompliance); // Monitorear el estado de la tarea en segundo plano
	
						if (job?.status === "Ok") {
							// CREATE ACTION FOR DOCUMENT UPLOADED AUTO VALIDATED
							addAction("Document uploaded", updateSupplier._id, document.documentID, docUniqueId, true)
	
							document.validated = true
							document.compliant = true
						} else if (job?.status === "Failed") {
							document.validated = false
							document.compliant = false
							document.document = "";
	
							return Swal.fire({
								icon: 'error',
								text: `${job?.result}`,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
								didOpen: () => {
									Swal.hideLoading()
								}
							})
						}
	
						Swal.close();
					}
				} else {
					if (data.repseNewDueDate) {
						document.dueDate = data.repseNewDueDate
					}
		
					// CREATE ACTION FOR DOCUMENT UPLOADED AUTO VALIDATED
					addAction("Document uploaded", updateSupplier._id, document.documentID, docUniqueId, true)
		
					document.reviewRequest = false
					document.validated = true
					document.compliant = true
				}
			}
	
			if (newValue && (property === "document" || property === "compliant") && updateSupplier.clients.some(client => client.status === "Review requested")) {
				const legalDocs = [
					...updateSupplier?.documentCompliance?.genericDocuments?.general ?? [],
					...updateSupplier?.documentCompliance?.genericDocuments?.historicDocuments ?? [],
					...updateSupplier?.documentCompliance?.genericDocuments?.periodic ?? []
				].filter(doc => doc._id?.toString() !== document._id?.toString())
	
				const isDocsWithReviewRequested = legalDocs.some(doc => doc.reviewRequest)
	
				if (!isDocsWithReviewRequested) {
					updateSupplier.clients.filter(client => {
						return client.status === "Review requested"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})
						
						client.status = "Ready for review"
						addAction("Ready for review", updateSupplier._id, client.clientID)
					})
				}
			}
	
			// LOGIC TO RUN IF THE SUPPLIER IS ON PRE-AUTHORIZED OR HOLD PAYMENT TO CHANGE THE STATUS TO READY FOR REVIEW
			if (updateSupplier.clients.some(client => client.status === "Pre-authorized" || client.status === "Hold payment")) {
	
				const lastDocumetsAdded = documentReference.map(reference =>
					updateDocumentComplianceList.find(document =>
						document.documentID.toString() === reference._id.toString())
				).filter(doc => doc !== undefined)
	
				const lastDocumentsWithDocument = lastDocumetsAdded.some(document => !document?.document)
				const lastDocumentsNonCompliant = lastDocumetsAdded.some(document => !document?.compliant)
	
				if (!lastDocumentsWithDocument) {
					updateSupplier.clients.filter(client => {
						return client.status === "Pre-authorized"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})
	
						client.status = "Ready for review"
						updateSupplier.firstTime = false
						sendKey = true
						addAction("Ready for review", updateSupplier._id, client.clientID)
					})
				}
	
				if (!lastDocumentsNonCompliant) {
					updateSupplier.clients.filter(client => {
						return client.status === "Hold payment"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})
						client.status = "Active"
						updateSupplier.holdPaymentDate = ""
					})
				}
			}
	
			if (!clientDocument) {
				updateSupplier.documentCompliance.genericDocuments.general = updateDocumentComplianceList.filter(doc => doc.dueDate === "Once")
				updateSupplier.documentCompliance.genericDocuments.periodic = updateDocumentComplianceList.filter(doc => doc.periodicity > 0)
				updateSupplier.documentCompliance.genericDocuments.historicDocuments = updateDocumentComplianceList.filter(doc => doc.dueDate === "Historic")
			} else {
				updateSupplier.documentCompliance.clients.find(client => client.clientID.toString() === selectedClientID.toString()).clientDocuments = {
					general: updateDocumentComplianceList.filter(doc => doc.dueDate === "Once"),
					periodic: updateDocumentComplianceList.filter(doc => doc.periodicity > 0),
					historicDocuments: updateDocumentComplianceList.filter(doc => doc.dueDate === "Historic"),
				}
			}
	
			if (property !== "comment" && (newValue || property !== "document")) {
				setShowDocumentModal(false)
			}
	
			const { staffs, ...supplierWithoutStaff } = updateSupplier;
	
			const supplierUpdated = complianceSetup
				? await handleSaveChanges(supplierWithoutStaff, sendKey)
				: await handleSaveChanges(supplierWithoutStaff)
	
			if (property === "compliant" && newValue && supplierUpdated) {
				const data = {
					username: userLogged?.username,
					docUniqueId
				}
	
				try {
					axios.patch(process.env.REACT_APP_CREATE_ACTION, data)
				} catch (err) {
					console.log(err)
				}
			}
	
			if (supplierUpdated) {
				resolve(true)
			}
		})
	}

	// FUNCTION TO SAVE THE NEW ONCE DOCUMENT AND CHANGE THE PAST ONE TO THE HISTORIC
	const saveNewOnceDocument = async (newDocument, documentFile, clientDocument) => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		const isRepse = legalDocumentIDs.repseFisica === newDocument.documentID || legalDocumentIDs.repseMoral === newDocument.documentID
		const docIsIDRepresentanteLegal = newDocument.documentID === legalDocumentIDs.idRepresentanteLegalMoral

		newDocument.document = documentFile

		const updateSupplier = JSON.parse(JSON.stringify(supplier))

		if (isRepse) {
			const answer = await validateDocument(
				newDocument,
				updateSupplier.informationCompliance.generalInformation.companyName,
				updateSupplier.informationCompliance.generalInformation.rfc)

			if (answer.errorMessage) {
				newDocument.validated = false
				newDocument.document = ""
				newDocument.compliant = false

				return Swal.fire({
					icon: 'error',
					text: answer.errorMessage,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.hideLoading()
					}
				})
			}

			let sendKey = false

			if (answer.repseNewDueDate) {
				newDocument.dueDate = answer.repseNewDueDate
			}

			newDocument.validated = true
			newDocument.compliant = true

			updateSupplier.documentCompliance.genericDocuments.periodic.unshift(newDocument)

			// LOGIC TO RUN IF THE SUPPLIER IS ON PRE-AUTHORIZED OR HOLD PAYMENT TO CHANGE THE STATUS TO READY FOR REVIEW
			if (updateSupplier.clients.some(client => client.status === "Pre-authorized" || client.status === "Hold payment")) {
				const updateDocumentComplianceList = JSON.parse(JSON.stringify(documentComplianceList))

				updateDocumentComplianceList.unshift(newDocument)

				const lastDocumetsAdded = documentReference.map(reference =>
					updateDocumentComplianceList.find(document =>
						document.documentID.toString() === reference._id.toString())
				).filter(doc => doc !== undefined)
	
				const lastDocumentsWithDocument = lastDocumetsAdded.some(document => !document?.document)
				const lastDocumentsNonCompliant = lastDocumetsAdded.some(document => !document?.compliant)
	
				if (!lastDocumentsWithDocument) {
					updateSupplier.clients.filter(client => {
						return client.status === "Pre-authorized"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})

						client.status = "Ready for review"
						updateSupplier.firstTime = false
						sendKey = true
						addAction("Ready for review", updateSupplier._id, client.clientID)
					})
				}

				if (!lastDocumentsNonCompliant) {
					updateSupplier.clients.filter(client => {
						return client.status === "Hold payment"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})
						client.status = "Active"
						updateSupplier.holdPaymentDate = ""
					})
				}
			}

			const supplierUpdated = complianceSetup
				? await handleSaveChanges(updateSupplier, sendKey)
				: await handleSaveChanges(updateSupplier)

			if (supplierUpdated) {
				const docUniqueId = supplierUpdated.documentCompliance.genericDocuments.periodic.find(doc => doc.documentID === newDocument.documentID)?._id

				docUniqueId && addAction("Document uploaded", updateSupplier._id, newDocument.documentID, docUniqueId, true)
			}

			return
		}

		if (docIsIDRepresentanteLegal) {
			let sendKey = false

			newDocument.dueDate = `${newDocument.monthCreatedAt}/01/${newDocument.yearCreatedAt + 1}`

			updateSupplier.documentCompliance.genericDocuments.periodic.unshift(newDocument)

			// LOGIC TO RUN IF THE SUPPLIER IS ON PRE-AUTHORIZED OR HOLD PAYMENT TO CHANGE THE STATUS TO READY FOR REVIEW
			if (updateSupplier.clients.some(client => client.status === "Pre-authorized" || client.status === "Hold payment")) {
				const updateDocumentComplianceList = JSON.parse(JSON.stringify(documentComplianceList))

				updateDocumentComplianceList.unshift(newDocument)

				const lastDocumetsAdded = documentReference.map(reference =>
					updateDocumentComplianceList.find(document =>
						document.documentID.toString() === reference._id.toString())
				).filter(doc => doc !== undefined)
	
				const lastDocumentsWithDocument = lastDocumetsAdded.some(document => !document?.document)
				const lastDocumentsNonCompliant = lastDocumetsAdded.some(document => !document?.compliant)
	
				if (!lastDocumentsWithDocument) {
					updateSupplier.clients.filter(client => {
						return client.status === "Pre-authorized"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})

						client.status = "Ready for review"
						updateSupplier.firstTime = false
						sendKey = true
						addAction("Ready for review", updateSupplier._id, client.clientID)
					})
				}

				if (!lastDocumentsNonCompliant) {
					updateSupplier.clients.filter(client => {
						return client.status === "Hold payment"
					}).forEach(client => {
						client.statusHistory.push({
							prevStatus: client.status,
							dateStatusChanged: serverDate.date
						})
						client.status = "Active"
						updateSupplier.holdPaymentDate = ""
					})
				}
			}

			const supplierUpdated = complianceSetup
				? await handleSaveChanges(updateSupplier, sendKey)
				: await handleSaveChanges(updateSupplier)

			if (supplierUpdated) {
				const docUniqueId = supplierUpdated.documentCompliance.genericDocuments.periodic.find(doc => doc.documentID === newDocument.documentID)?._id

				docUniqueId && addAction("Document uploaded", updateSupplier._id, newDocument.documentID, docUniqueId)
			}

			return
		}

		const generalDocuments = !clientDocument
			? updateSupplier.documentCompliance.genericDocuments.general
			: updateSupplier.documentCompliance.clients.find(client =>
				client.clientID.toString() === selectedClientID.toString()).clientDocuments.general

		const documentIndex = generalDocuments.findIndex(suppDoc =>
			suppDoc.documentID.toString() === newDocument.documentID.toString())

		if (documentIndex !== -1) {
			generalDocuments[documentIndex].dueDate = "Historic"
			!clientDocument
				? updateSupplier.documentCompliance.genericDocuments.historicDocuments.unshift(generalDocuments[documentIndex])
				: updateSupplier.documentCompliance.clients.find(client =>
					client.clientID.toString() === selectedClientID.toString()).clientDocuments.historicDocuments.unshift(generalDocuments[documentIndex])

			generalDocuments[documentIndex] = newDocument

			const supplierUpdated = await handleSaveChanges(updateSupplier)

			const generalDocs = JSON.parse(JSON.stringify(supplierUpdated.documentCompliance.genericDocuments.general))
			const clientDocs = JSON.parse(JSON.stringify(supplierUpdated.documentCompliance.clients))?.flatMap(client => JSON.parse(JSON.stringify(client.clientDocuments.general)))
			const allDocs = [...generalDocs, ...clientDocs]
			const docUniqueId = allDocs.find(doc => doc?.documentID === newDocument?.documentID)?._id

			addAction("Document uploaded", updateSupplier._id, newDocument?.documentID, docUniqueId)
		}
	}

	return (
		<section className="documentCompliance">
			<div className="tableHeader">
				<h5 className="title">Documents</h5>
				<div className="labels">
					<span className="withDot green">Compliant</span>
					<span className="withDot white">No actions needed</span>
					<span className="withDot red">Non-compliant</span>
					<span className="withDot grey">Pending month</span>
					<span className="withDot yellow">Pending review</span>
					<span className="withDot darkYellow">Comment</span>
				</div>

				<Dropdown className="yearButton">
					<Dropdown.Toggle>
						{selectedYear}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{yearsToShow.map((year, index) => (
							<Dropdown.Item key={index} onClick={(e) => setSelectedYear(+e.target.innerText)}>{year}</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<div className="tableInformation">
				<div className="tableLeftSide">
					<div className="tableTitle">
						<span className="documentNumber"></span>
						<span className="documentName">Name</span>
					</div>
					{documentReference.map((reference, index) => (
						<div key={index} className="tableRow">
							<span className="documentNumber">{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>
							<OverlayTrigger
								key={index}
								overlay={
									<Tooltip id={`tooltipDocumentName`} style={{ position: "fixed" }}>
										{reference.documentName}
									</Tooltip>
								}>
								<span className="documentName">{reference.documentName}</span>
							</OverlayTrigger>
						</div>
					))}

					<div className="tableFooter">
						<span className="footerTitle">Monthly percent compliancy</span>
					</div>
				</div>

				<div className="tableRightSide">
					<div className="tableTitle">
						{monthlyCompliancePercent.find(yearPercent => yearPercent?.year === selectedYear)
							?.months.map((month, index) => (
								<span key={index} className={`documentMonth ${selectedYear === serverDate?.year && month.month === serverDate.month ? "current" : ""}`}>
									{month.shortLabel}
								</span>
							))}
					</div>

					{documentReference.map((reference, referenceIndex) => (
						<div key={referenceIndex} className="tableRow">
							{monthlyCompliancePercent.find(yearPercent => yearPercent?.year === selectedYear)
								?.months.map((month, monthIndex) => (
									<div key={referenceIndex + monthIndex} className={`squareContainer ${selectedYear === serverDate?.year && month.month === serverDate.month ? "current" : ""}`}>
										{handleGetDocumentSquare(reference, month.month, false)}
									</div>
								))}
						</div>
					))}

					<div className="tableFooter">
						{monthlyCompliancePercent.find(yearPercent => yearPercent?.year === selectedYear)
							?.months.map((month, index) => (
								<span key={index} className={`monthPercent ${selectedYear === serverDate?.year && month.month === serverDate.month ? "current" : ""}`}>
									{month.percent.toFixed(2)}%
								</span>
							))}
					</div>
				</div>
			</div>

			{clientDocumentReference.length > 0 &&
				<React.Fragment>
					<div className="tableHeader">
						<h5 className="title">Documents by client</h5>
						<React.Fragment>
							<img className="clientFilterIcon" src={clientIcon} alt="Prodensa Supplier" />
							<Dropdown className="clientSelector">
								<Dropdown.Toggle>
									{clientDocumentReference.find(client =>
										client.entityID.toString() === selectedClientID.toString())?.entityName}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{clientDocumentReference.map((entity, index) => (
										<Dropdown.Item key={index} onClick={() => setSelectedClientID(entity.entityID)}>{entity.entityName}</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</React.Fragment>
					</div>

					<div className="tableInformation">
						<div className="tableLeftSide">
							<div className="tableTitle">
								<span className="documentNumber"></span>
								<span className="documentName">Name</span>
							</div>
							{clientDocumentReference.find(client => client.entityID.toString() === selectedClientID.toString()).documentsToShow.map((reference, index) => (
								<div key={index} className="tableRow">
									<span className="documentNumber">{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>
									<OverlayTrigger
										key={index}
										overlay={
											<Tooltip id={`tooltipDocumentName`} style={{ position: "fixed" }}>
												{reference.documentName}
											</Tooltip>
										}>
										<span className="documentName">{reference.documentName}</span>
									</OverlayTrigger>
								</div>
							))}

							<div className="tableFooter">
								<span className="footerTitle">Monthly percent compliancy</span>
							</div>
						</div>

						<div className="tableRightSide">
							<div className="tableTitle">
								{monthlyCompliancePercent.find(yearPercent => yearPercent?.year === selectedYear)
									?.months.map((month, index) => (
										<span key={index} className={`documentMonth ${selectedYear === serverDate?.year && month.month === serverDate.month ? "current" : ""}`}>
											{month.shortLabel}
										</span>
									))}
							</div>

							{clientDocumentReference.find(client => client.entityID.toString() === selectedClientID.toString()).documentsToShow.map((reference, referenceIndex) => (
								<div key={referenceIndex} className="tableRow">
									{monthlyCompliancePercent.find(yearPercent => yearPercent?.year === selectedYear)
										?.months.map((month, monthIndex) => (
											<div key={referenceIndex + monthIndex} className={`squareContainer ${selectedYear === serverDate?.year && month.month === serverDate.month ? "current" : ""}`}>
												{handleGetDocumentSquare(reference, month.month, true)}
											</div>
										))}
								</div>
							))}

							<div className="tableFooter">
								{monthlyCompliancePercent.find(yearPercent => yearPercent?.year === selectedYear)
									?.clients.find(client => client.entityID.toString() === selectedClientID.toString())?.months.map((month, index) => (
										<span key={index} className={`monthPercent ${selectedYear === serverDate?.year && month.month === serverDate.month ? "current" : ""}`}>
											{month.percent.toFixed(2)}%
										</span>
									))}
							</div>
						</div>
					</div>
				</React.Fragment>}

			{modalData && <DocumentInformationModal
				show={showDocumentModal}
				onHide={() => setShowDocumentModal(false)}
				documentData={modalData}
				changeDocumentInformation={changeDocumentInformation}
				supplier={supplier}
				setSupplier={setSupplier}
				supplierRfc={supplier.informationCompliance.generalInformation.rfc}
				saveNewOnceDocument={saveNewOnceDocument}
				darkLightMode={props.darkLightMode}
			/>}
		</section >
	)
}