import "../assets/css/pages/landing.css"
import countryInformation from "../assets/jsonData/countryInformation.json"
import supplierCommodities from "../assets/jsonData/supplierCommodities.json"

import logo from "../assets/images/logo.png"
import video from "../assets/videos/prodensa-header-loop.mp4"
import scrollDown from "../assets/images/doubleArrowDown.svg"

import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick"
import { Row, Col, Tab, Tabs, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import Swal from 'sweetalert2'
import axios from "axios"
import { formatCommas } from "../utilities/formatCommas"
import { checkTheEmail } from "../utilities/validateEmail"
import { addAction } from "../utilities/utils"

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS;
const URI_CHECK_RFC = process.env.REACT_APP_URI_SUPPLIERS_CHECK_RFC;
const URI_INDUSTRY = process.env.REACT_APP_URI_INDUSTRY
const URI_SERVER_DATE = process.env.REACT_APP_URI_SERVER_DATE
const URI_CLIENT_BY_URL = process.env.REACT_APP_URI_CLIENT_BY_URL
const URI_REFFERAL_BY_CODE = process.env.REACT_APP_URI_REFFERAL_BY_CODE
const VALIDATE_RFC_URI = process.env.REACT_APP_URI_SUPPLIER_VALIDATE_RFC
const URI_BLACK_LIST_SUPPLIER = process.env.REACT_APP_URI_BLACK_LIST_SUPPLIERS;
const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function LandingClient() {
	// CATEGORIES INFORMATION
	const [industrySupplier, setIndustrySupplier] = useState(null)

	//SERVER DATE
	const [serverDate, setServerDate] = useState(null);

	// CONTROL VARIABLES TO SAVE VALUES FROM THE INPUTS
	const [country, setCountry] = useState("México");
	const [typePerson, setTypePerson] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [rfc, setRfc] = useState("");
	const [yearFounded, setYearFounded] = useState(0);
	const [numberEmployee, setNumberEmployee] = useState("");
	const [averageAnualSales, setAverageAnualSales] = useState(0);
	const [curriculum, setCurriculum] = useState("");
	const [website, setWebsite] = useState("");
	const [streetAndNumber, setStreetAndNumber] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [contactName, setContactName] = useState("");
	const [role, setRole] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [categories, setCategories] = useState([]);
	const [otherCategories, setOtherCategories] = useState("");
	const [commodities, setCommodities] = useState([]);
	const [otherCommodities, setOtherCommodities] = useState("");
	const [hasRepse, setHasRepse] = useState(false);
	const [numberRepse, setNumberRepse] = useState("");
	const [description, setDescription] = useState("");
	const [clientID, setClientID] = useState("");
	const [refferalMemberID, setRefferalMemberID] = useState("");

	// CONTROL VARIABLES TO SHOW AND HIDE MENUS 
	const [showTab, setShowTab] = useState("general");

	// VARIABLE TO CONTROL THE AFTER SUBMIT INFORMATION
	const [informationSubmitted, setInformationSubmitted] = useState(false)

	// VARIABLE TO CONTROL WHEN THE USER CAN SUBMIT OR NOT
	const [submit, setSubmit] = useState(false)

	// VARIABLE TO CONTROL THE NAVIGATE
	const navigate = useNavigate()

	// VARIABLE TO KNOW WICH CLIENT LANDING IS
	const { clientUrl } = useParams()

	// LOGIC TO MANAGE REFFERA CODE VARIABLE
	const locationUrl = useLocation();
	const searchParams = new URLSearchParams(locationUrl.search);
	const refferalCodeVariable = searchParams.get("refferalCode");
	const refferalCode = refferalCodeVariable

	// REFERENCE FOR THE COMMODITIES MENU
	const commodityRef = useRef(null)

	// ATTRIBUTES FOR THE DESCRIPTION SLIDER
	const slickDescription = {
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		infinite: true,
		adaptiveHeight: true,
	}

	// CALL THE FUNCTIONS TO GET ALL THE SUPPLIERS INDUSTRIES AND THE SERVER DATE
	useEffect(() => {
		getClientByUrl(clientUrl)

		document.addEventListener("click", closeMenus)

		return function cleanUp() {
			document.removeEventListener("click", closeMenus)
		}
	}, [])

	// USE EFECT TO MANAGE COUNTRY STATE
	useEffect(() => {
		setTypePerson("");
	}, [country])

	// USE EFECT TO MANAGE TYPE OF PERSON STATE
	useEffect(() => {
		setCompanyName("")
		setRfc("")
		setStreetAndNumber("")
		setZipCode("")
		setCity("")
		setState("")
	}, [typePerson])

	// FUNCTION TO CLOSE MENU FILTERS
	const closeMenus = (e) => {
		if (commodityRef.current && commodityRef.current.contains(e.target)) {
			commodityRef.current.classList.toggle("active")
			commodityRef.current.nextElementSibling.classList.toggle("active")
		} else {
			commodityRef.current.classList.remove("active")
			commodityRef.current.nextElementSibling.classList.remove("active")
		}
	}

	// FUNCTION TO VERIFY IF ALL INPUTS HAVE INFORMATION
	const checkInputs = () => {
		var emptyInputs = 0

		if (!country) emptyInputs++
		if (!typePerson) emptyInputs++
		if (!companyName) emptyInputs++
		if (!rfc) emptyInputs++
		if (!yearFounded) emptyInputs++
		if (!numberEmployee) emptyInputs++
		if (!averageAnualSales) emptyInputs++
		if (!website) emptyInputs++
		if (!streetAndNumber) emptyInputs++
		if (!zipCode) emptyInputs++
		if (!city) emptyInputs++
		if (!state) emptyInputs++
		if (!contactName) emptyInputs++
		if (!role) emptyInputs++
		if (!email) emptyInputs++
		if (!phone) emptyInputs++
		if (categories.length === 0) emptyInputs++
		if (commodities.length === 0) emptyInputs++
		if (hasRepse) {
			if (!numberRepse) emptyInputs++
		}
		if (!description) emptyInputs++

		return emptyInputs === 0 ? true : false
	}

	// FUNCTION TO CHECK IF THE RFC ALREADY EXISTS IN THE SYSTEM
	const checkRFC = (rfcRecived) => {
		if (rfcRecived !== "") {
			// CHECK IF THE RFC ALREADY EXIST ON THE DB
			axios.post(URI_CHECK_RFC, {
				rfc: rfcRecived,
			}).then(response => {
				if (response.data.exist) {
					Swal.fire({
						title: `Hello ${response.data.supplier.informationCompliance.generalInformation.companyName}, you're already registered`,
						text: `Do you want to add ${clientUrl} as a new client?`,
						icon: "warning",
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonText: "Yes, add it!",
					}).then((result) => {
						if (result.isConfirmed) {
							addClientToSupplier(response.data.supplier, clientID)
						} else {
							setRfc("")
						}
					});
				} else {
					setSubmit(true)
					Swal.close()
				}
			}).catch(err => {
				console.log("Error al checar el RFC: " + err)
				Swal.fire({
					icon: 'error',
					text: 'Error while checking the RFC',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			})
		} else {
			setSubmit(false)
			Swal.close()
		}

	}

	//FUNCTION TO ADD THE CLIENT TO EXISTING SUPPLIER
	const addClientToSupplier = (supplier, clientID) => {
		if (!supplier.clients.some(client => client.clientID === clientID)) {
			const supplierToSave = supplier
			let statusToAdd = ""

			const alreadyClientActive = supplierToSave.clients.some(client => client.status === "Active" || client.status === "Authorized" || client.status === "Ready for review")

			const clientInReviewRequest = supplierToSave.clients.some(client => client.status === "Review requested")

			if (alreadyClientActive || clientInReviewRequest) {
				statusToAdd = clientInReviewRequest ? "Review requested" : "Ready for review"
			} else {
				statusToAdd = "New request"
			}

			supplierToSave.clients = [...supplierToSave.clients, {
				clientID: clientID,
				status: statusToAdd,
				staffs: [],
			}]

			axios.put(URI_SUPPLIER + supplierToSave._id, {
				supplier: JSON.stringify(supplierToSave)
			}).then(response => {
				!clientInReviewRequest && addAction(statusToAdd, supplierToSave._id, clientID)
				
				Swal.fire({
					icon: 'success',
					text: 'Client added to the supplier successfully',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				setRfc("")
				setInformationSubmitted(true)
			}).catch(err => {
				console.log("Error al actualizar el supplier: " + err)
			})
		} else {
			Swal.fire({
				icon: 'warning',
				title: `This client is already registered to the supplier ${supplier.informationCompliance.generalInformation.companyName}`,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			setRfc("")
		}
	}

	// FUNCTION TO SAVE THE SUPPLIER
	const sendData = async () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		const checkedInputs = checkInputs()

		// CHECK IF ALL THE INPUTS HAVE INFORMATION
		if (checkedInputs) {
			const response = await validateRFC(rfc)

			const formData = new FormData();
			formData.append("country", countryInformation.find(countries => countries.country === country).code)
			formData.append("typePerson", typePerson)
			formData.append("companyName", companyName)
			formData.append("rfc", rfc)
			formData.append("yearFounded", yearFounded)
			formData.append("numberEmployee", numberEmployee)
			formData.append("averageAnualSales", averageAnualSales)
			formData.append("website", website)
			formData.append("streetAndNumber", streetAndNumber)
			formData.append("zipCode", zipCode)
			formData.append("city", city)
			formData.append("state", state)
			formData.append("name", contactName)
			formData.append("role", role)
			formData.append("email", email)
			formData.append("phone", phone)
			categories.forEach(element => {
				formData.append("categories[]", element)
			});
			formData.append("otherCategories", otherCategories)
			commodities.forEach(element => {
				formData.append("commodities[]", element)
			});
			formData.append("otherCommodities", otherCommodities)
			formData.append("hasRepse", hasRepse)
			formData.append("numberRepse", numberRepse)
			formData.append("description", description)
			formData.append("clientID", clientID)
			formData.append("refferalMemberID", refferalMemberID)

			formData.append("yearCreatedAt", serverDate.year)
			formData.append("monthCreatedAt", serverDate.month)
			formData.append("dayCreatedAt", serverDate.day)

			if (curriculum.name) {
				formData.append("document", curriculum, `${serverDate.year}-${serverDate.month}-${curriculum.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)

				formData.append("curriculum", `${serverDate.year}-${serverDate.month}-${curriculum.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
			} else {
				formData.append("curriculum", curriculum)
			}

			if (!response.rfcFounded) {
				axios.post(URI_SUPPLIER, formData).then(response => {
					Swal.fire({
						icon: 'success',
						text: 'Request submitted successfully',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})

					setInformationSubmitted(true)
				}).catch(err => {
					Swal.fire({
						icon: 'error',
						text: 'Request submitted failed',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					console.log("Error al agregar el supplier: " + err);
				})
			} else {
				axios.post(URI_BLACK_LIST_SUPPLIER, formData).then(response => {
					Swal.fire({
						icon: 'error',
						title: "Sorry, you couldn't subscribe",
						text: 'Please contact us for more information',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						didOpen: () => {
							Swal.hideLoading()
						},
					})
				}).catch(err => {
					Swal.fire({
						icon: 'error',
						text: 'Request submitted failed',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					console.log("Error al agregar el supplier: " + err);
				})
			}
		} else {
			Swal.fire({
				icon: 'error',
				text: 'Please fill all the information',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: () => {
					Swal.hideLoading()
				},
			})
		}
	}

	// FUNCTION TO ADD OR REMOVE THE CATEGORIES SELECTED
	const addCategory = (target) => {
		const categoriesCopy = [...categories]
		if (categoriesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			categoriesCopy.splice(categoriesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			categoriesCopy.push(target.value.toUpperCase())
		}
		setCategories(categoriesCopy)
	}

	// FUNCTION TO ADD OR REMOVE THE CATEGORIES SELECTED
	const addCommodity = (target) => {
		const commoditiesCopy = [...commodities]
		if (commoditiesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			commoditiesCopy.splice(commoditiesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			commoditiesCopy.push(target.value.toUpperCase())
		}

		if (commoditiesCopy.includes("NOT APPLICABLE")) {
			setCommodities(["NOT APPLICABLE"])
		} else {
			setCommodities(commoditiesCopy)
		}
	}

	// FUNCTION TO GET THE SERVER DATE
	const getServerDate = () => {
		axios.get(URI_SERVER_DATE).then(response => {
			setServerDate(response.data)
		}).catch(err => {
			console.log("Error al obtener la fecha del servidor" + err)
		})
	}

	// FUNCTION TO GET ALL DE INDUSTRIES
	const getAllSupplierIndustries = () => {
		axios.get(URI_INDUSTRY).then(response => {
			setIndustrySupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener la fecha del servidor" + err)
		})
	}

	// FUNCTION TO GET THE SERVER DATE
	const getClientByUrl = (clientUrl) => {
		axios.post(URI_CLIENT_BY_URL, { clientUrl: clientUrl }).then(response => {
			const client = response.data
			if (client) {
				setClientID(client.entities.find(entity => entity.entityUrlName === clientUrl)._id.toString())
				getAllSupplierIndustries()
				getServerDate();
				if (client.urlName === "cattenna") {
					getRefferalByCode(refferalCode)
				}
			} else {
				navigate("/404")
			}
		}).catch(err => {
			console.log("Error al obtener la url del cliente" + err)
		})
	}

	// FUNCTION TO GET ALL DE INDUSTRIES
	const getRefferalByCode = (code) => {
		axios.get(URI_REFFERAL_BY_CODE + code).then(response => {
			setRefferalMemberID(response.data._id ?? "")
		}).catch(err => {
			console.log("Error al obtener al miembro referrido" + err)
		})
	}

	// FUNCTION TO VALIDATE THE RFC
	const validateRFC = async (rfcToCheck) => {
		let rfcFounded

		await axios.post(VALIDATE_RFC_URI, {
			rfcToCheck: rfcToCheck,
			checkCattenna: true
		}).then(response => {
			rfcFounded = response.data;
		}).catch(err => {
			console.log("Error al validar el rfc: " + err)
		});

		return rfcFounded
	}

	const handleEmailCheck = async () => {
		try {
			const res = await checkTheEmail(email)

			setEmail(res)
		} catch (error) {
			console.log(error)
			setEmail("")
		}
	}

	return (
		<>
			<nav>
				<div className="container">
					<img className="logo" src={logo} alt="Prodensa Supplier" />
					<a className="anchorSection" href="#selection">How it works?</a>
					<a className="anchorSection" href="#apply">Apply as a supplier</a>
					<Link to={"/login"} className="btnBgBlueTextGradient">
						Login
					</Link>
				</div>
			</nav>

			<section className="videoLoop">
				<video src={video} muted autoPlay controls loop typeof="video/mp4"></video>
				<a className="scrollDown" href="#selection">
					<img src={scrollDown} alt="Prodensa Supplier" />
				</a>
			</section>

			<section id="selection" className="selection">
				<div className="container">
					<Row>
						<Col className="ps-lg-5" xs={{ span: 10, offset: 1 }} lg={{ span: 3, offset: 1 }}>
							<h3 className="title">Our selection process</h3>
						</Col>
						<Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 0 }}>
							<Slider className="slickDescription" {...slickDescription}>
								<p className="description">If you're interested in becoming part of our directory of authorized suppliers, first step is to apply for a pre-authorization. In this step we'll ask for general information about your company</p>
								<p className="description">We will review your request information and will let you know if your company has been pre-authorize to continue with the process. If authorized, your company will be assigned a user to our supplier platform.</p>
								<p className="description">After being pre-authorize you'll need to login into your account and submit for review detailed information and documents about your company. This will help us make an assertive decision for authorizing your company.</p>
								<p className="description">If authorize you'll receive a notification letting you know your company is approve, and will be considered for current and/or future needs.</p>
								<p className="description">As an active provider, you are requiere to comply with all legal, fiscal, and specific documentation for your industry in the time frame specified.</p>
								<p className="description">All information submitted for review must be true and verifiable, failing to comply can result in automatic disqualification as a supplier.</p>
							</Slider>
						</Col>
					</Row>
				</div>
			</section >

			<section id="apply" className={`apply`}>
				<div className="container">
					<Row>
						<Col xs={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
							<h3>Apply for pre-authorization</h3>
							<p className="description">Want to become an authorize provider of Prodensa? Please fill out the following information about your company and its services. Our team will review your request and pre-authorize your application if you meet all of our requirements.</p>

							<div className={`tabPane ${informationSubmitted ? "d-none" : ""}`}>
								<Tabs activeKey={showTab} onSelect={(e) => setShowTab(e)}>
									<Tab eventKey="general" title="General Information">
										<h4 className="title">General information</h4>
										<p className="description">Please fill out the following fields, be sure to complete all information requested.</p>
										<form>
											<div className="inputContainer">
												<span className="label">Company country *</span>
												<Dropdown>
													<Dropdown.Toggle className={country ? "" : "default"}>
														{country ? country : "Select country"}
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{countryInformation.map((information, index) => (
															<Dropdown.Item key={index} onClick={(e) => setCountry(e.target.innerText)}>{information.country}</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div className="inputContainer">
												<span className="label">
													{countryInformation.find(countries => {
														return countries.country === country
													}).typePersonLabel} *
												</span>
												<Dropdown>
													<Dropdown.Toggle className={typePerson ? "" : "default"}>
														{typePerson ? typePerson : "Select option"}
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{countryInformation.find(countries => countries.country === country).typePersonValues.map((person, index) => (
															<Dropdown.Item key={index} onClick={(e) => setTypePerson(e.target.innerText)}>{person}</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div className="inputContainer">
												<span className="label">Presentation/Curriculum <label>(PDF File)</label></span>
												<input id="file" onChange={(e) => {
													if (e.target.files[0]) {
														if (e.target.files[0].type === "application/pdf") {
															setCurriculum(e.target.files[0])
														} else {
															e.target.value = null
															Swal.fire({
																icon: 'error',
																text: 'The file is not a PDF.',
																showConfirmButton: false,
																timer: 5000,
																timerProgressBar: true,
															})
														}
													}
												}} accept=".pdf" type="file" />
											</div>
											<div className="w-100"></div>
											<div className="inputContainer">
												<span className="label" >{country === "México" ? "Razon Social *" : "Company name"}</span>
												<OverlayTrigger placement="top" trigger="focus"
													overlay={<Tooltip id="validatedTooltip">
														{country === "México" ? "Razon should be exactly as in your \"constancia de situacion fiscal\"." : "Company name"}
													</Tooltip>}>

													<input value={companyName} onChange={(e) => setCompanyName(e.target.value)} type="text" placeholder="Company Name" />
												</OverlayTrigger>
											</div>
											<div className={`inputContainer`}>
												<span className="label">
													{countryInformation.find(countries => {
														return countries.country === country
													}).rfcLabel} *
												</span>
												<input id="inputRfc" value={rfc}
													onChange={(e) => {
														if (country === "México")
															setRfc(e.target.value)
														else if (country === "United states of america") {
															const value = e.target.value.replace(/[^\d]/g, "")
															setRfc(value)
														}
													}}
													autoComplete="off"
													onBlur={() => checkRFC(rfc)}
													type="text"
													placeholder={countryInformation.find(countries => {
														return countries.country === country
													}).rfcPlaceholder} />
											</div>
											<div className="inputContainer">
												<span className="label">Founded year *</span>
												<input value={yearFounded === 0 ? "" : yearFounded} onChange={(e) => setYearFounded(e.target.value)} type="number" placeholder="ex: 2024" />
											</div>
											<div className="inputContainer">
												<span className="label">Number of employees *</span>
												<Dropdown>
													<Dropdown.Toggle className={numberEmployee ? "" : "default"}>
														{numberEmployee ? numberEmployee : "Select range"}
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>0</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>Autoempleado</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>menos de 10</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>10 - 50</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>51 - 100</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>101 - 150</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>151 - 200</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>201 - 300</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>301 - 500</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>501 - 1,000</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>1,001 - 5,000</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>5,001 - 10,000</Dropdown.Item>
														<Dropdown.Item onClick={(e) => setNumberEmployee(e.target.innerText)}>más de 10,001</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div className="inputContainer dollars">
												<span className="label">Average annual sales *</span>
												<input value={formatCommas(averageAnualSales).formattedValue ?? ""}
													onChange={(e) => setAverageAnualSales(formatCommas(e.target.value ? e.target.value : 0).numericValue)}
													type="text"
													placeholder="ex: 1000000"
												/>
											</div>
											<div className="inputContainer">
												<span className="label">Website / Social network *</span>
												<input value={website} onChange={(e) => setWebsite(e.target.value)} type="text" placeholder="ex: www.prodensa.com" />
											</div>
											<div className="inputContainer">
												<span className="label">Official address *</span>
												<input value={streetAndNumber} onChange={(e) => setStreetAndNumber(e.target.value)} type="text" placeholder="Street and number" />
											</div>
											<div className="inputContainer">
												<input value={zipCode} onChange={(e) => setZipCode(e.target.value)} type="text" placeholder="Zip Code" />
											</div>
											<div className="inputContainer">
												<input value={city} onChange={(e) => setCity(e.target.value)} type="text" placeholder="City" />
											</div>
											<div className="inputContainer">
												<Dropdown>
													<Dropdown.Toggle className={`${state ? "" : "default"}`}>
														{state ? state : "State"}
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{countryInformation.find(countries => countries.country === country).states.map((state, index) => (
															<Dropdown.Item key={index} onClick={(e) => setState(e.target.innerText)}>{state.name}</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div className="inputContainer">
												<span className="label">Contact details *</span>
												<input value={contactName} onChange={(e) => setContactName(e.target.value)} type="text" placeholder="Contact name" />
											</div>
											<div className="inputContainer">
												<input value={role} onChange={(e) => setRole(e.target.value)} type="text" placeholder="Role in company" />
											</div>
											<div className="inputContainer">
												<input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email address" onBlur={handleEmailCheck} />
											</div>
											<div className="inputContainer">
												<input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder="Contact phone number" />
											</div>
											<div className="w-100"></div>
											<button className="btnBgBlueTextGradient" type="button" onClick={() => setShowTab("industry")}>
												Next
											</button>
										</form>
									</Tab>

									<Tab eventKey="industry" title="Industry selection">
										<h4 className="title">Industry selection</h4>
										<p className="description">What industry does your company belong to?</p>
										<div className="industryContainer">
											<div className="inputContainer">
												<span className="label">Category of supplier *</span>
												<Dropdown autoClose="outside">
													<Dropdown.Toggle className={categories.length > 0 ? "" : "default"}>
														{categories.length > 0 ? categories.map((cat, index) => {
															return categories.length > index + 1 ? cat + ", " : cat
														}) : "Category/Industry"}
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="optionCategory">
															<input id={`categoriesOther`} type="checkbox" value={"OTHER"} onClick={(e) => addCategory(e.target)} />
															<label htmlFor={`categoriesOther`}>Other</label>
														</div>
														{industrySupplier && industrySupplier.sort((a, b) => a.name.localeCompare(b.name)).map((industry, index) => {
															if (industry.name !== "Global") {
																return (
																	<div key={index} className="optionCategory">
																		<input id={`categories${index}`} type="checkbox" value={industry.name} onClick={(e) => addCategory(e.target)} />
																		<label htmlFor={`categories${index}`}>{industry.name}</label>
																	</div>
																)
															}
														})}
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div className={`inputContainer otherOptions ${categories.includes("OTHER") ? "show" : ""}`}>
												<span className="label">Other categories</span>
												<input value={otherCategories} onChange={(e) => setOtherCategories(e.target.value)} type="text"
													placeholder="It systems, Automation, Cleaning Services" />
											</div>
											<div className="inputContainer commodityContainer">
												<span className="label">Commodities of supplier *</span>
												<div ref={commodityRef} className={`buttonCommodities ${commodities.length < 1 ? "default" : ""}`}>
													{commodities.length > 0 ? commodities.map((commodity, index) => {
														return commodities.length > index + 1 ? commodity + ", " : commodity
													}) : "Commodities"}
												</div>
												<div className="commodityList" onClick={(e) => e.stopPropagation()}>
													<div className="optionCommodity">
														<input id={`commodityN/A`} value={"NOT APPLICABLE"} type="checkbox" onChange={(e) => addCommodity(e.target)}
															checked={commodities.includes("NOT APPLICABLE")} />
														<label htmlFor={`commodityN/A`}>Not applicable</label>
													</div>
													<div className={`optionCommodity ${commodities.includes("NOT APPLICABLE") ? "disabled" : ""}`}>
														<input id={`commodityOther`} value={"OTHER"} type="checkbox" onChange={(e) => addCommodity(e.target)}
															checked={commodities.includes("OTHER")} />
														<label htmlFor={`commodityOther`}>Other</label>
													</div>
													{supplierCommodities && supplierCommodities.map((commodity, index) => (
														<React.Fragment key={index}>
															<h5 className="commodityName">{commodity.commodityName}</h5>
															{commodity.commodityProcess.map((process, processIndex) => (
																<div key={`${index}${processIndex}`} className={`optionCommodity ${commodities.includes("NOT APPLICABLE") ? "disabled" : ""}`}>
																	<input id={`commodity${index}${processIndex}`} value={process} type="checkbox" onChange={(e) => addCommodity(e.target)}
																		checked={commodities.includes(process.toUpperCase())} />
																	<label htmlFor={`commodity${index}${processIndex}`}>{process}</label>
																</div>
															))}
														</React.Fragment>
													))}
												</div>
											</div>
											<div className={`inputContainer otherOptions ${commodities.includes("OTHER") ? "show" : ""}`}>
												<span className="label">Other commodities</span>
												<input value={otherCommodities} onChange={(e) => setOtherCommodities(e.target.value)} type="text"
													placeholder="Display, Overlays, Laser Cutting" />
											</div>
											<div className="inputContainer radioRepse">
												<span className="label">Do you have a REPSE number? *</span>
												<input id="repseYes" defaultChecked={hasRepse ? true : false} className="yes" type="radio" name="repseNumer" onClick={() => setHasRepse(true)} />
												<label htmlFor="repseYes">Yes</label>
												<input id="repseNo" defaultChecked={!hasRepse ? true : false} className="no" type="radio" name="repseNumer" onClick={() => { setHasRepse(false); setNumberRepse("") }} />
												<label htmlFor="repseNo">No</label>
											</div>
											<div className={`inputContainer textRepse ${hasRepse ? "show" : ""}`}>
												<span className="label">REPSE Number *</span>
												<input value={numberRepse} onChange={(e) => setNumberRepse(e.target.value)} disabled={hasRepse ? false : true} type="text" placeholder="89034-120934-901234-932814" />
											</div>
											<div className="inputContainer">
												<span className="label">Description of products/services offered by company *</span>
												<textarea value={description} onChange={(e) => setDescription(e.target.value)} rows={5} placeholder="Description" />
											</div>
											<p className="terms">By clicking “Submit request” I hereby certify that all the information is true and can be verified. I have read and agree to the <a href="#">Privacy Policy</a> and <a href="#">Terms and Conditions.</a></p>
											<button className={`btnBgBlueTextGradient ${!submit ? "disabled" : undefined}`} onClick={sendData} disabled={!submit}>
												Submit request
											</button>
										</div>
									</Tab>
								</Tabs>
							</div>

							<h3 className={`thankTitle ${!informationSubmitted ? "d-none" : ""}`}>Thank you for your information,<br />We will contact you</h3>
						</Col>
					</Row>
				</div>
			</section>

			<section className="footer">
				<span>Powered by Prodensa</span>
				<a href="mailto:cattenna@prodensa.com">Contact Us</a>
				<a href={URI_UPLOADS + "privacyPolicy/avisoPrivacidad.pdf"} target="_blank">Privacy Policy</a>
			</section>
		</>
	)
}