import "../../assets/css/dashboard/newRequest.css"

import sortIcon from "../../assets/images/sort.svg"
import next from "../../assets/images/arrowForward.svg"
import view from "../../assets/images/viewProfile.svg"

import NewRequestModal from "../requests/newRequest.jsx"

import React, { useState } from "react";
import moment from "moment"
import { useCookies } from "react-cookie"

export default function NewRequest(props) {
	const [suppliersNewRequest, setSuppliersNewRequest] = useState(props.suppliersNewRequest)

	const [currentPage, setCurrentPage] = useState(1)

	const [cookie] = useCookies(["userLogged"])
    const userLogged = cookie.userLogged

	const [modalSupplier, setModalSupplier] = useState({
		show: false
	})

	let totalPages = Math.ceil(suppliersNewRequest.length / 20);
	let indexOfLastItem = +currentPage * 20;
	let indexOfFirstItem = indexOfLastItem - 20;
	let currentItems = suppliersNewRequest.slice(indexOfFirstItem, indexOfLastItem);

	let paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		// ORDER FALSE = ASC; ORDER TRUE = DSC 
		switch (sortBy) {
			case "Name":
				setSuppliersNewRequest(suppliersNewRequest.sort((a, b) => {
					let fa = a.companyName.toLocaleLowerCase();
					let fb = b.companyName.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Industry":
				setSuppliersNewRequest(suppliersNewRequest.sort((a, b) => {
					let fa = a.industries[0].toLocaleLowerCase();
					let fb = b.industries[0].toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Location":
				setSuppliersNewRequest(suppliersNewRequest.sort((a, b) => {
					let fa = a.location;
					let fb = b.location;

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Date":
				setSuppliersNewRequest(suppliersNewRequest.sort((a, b) => {
					let da = new Date(a.createdAt);
					let db = new Date(b.createdAt);
					if (!order) {
						return da - db
					} else {
						return db - da
					}
				}))
				setOrder(!order)
				break
			default:
				break;
		}
	}

	const handleModalSupplier = (supplier) => {
		setModalSupplier({
			show: true,
			supplierID: supplier.supplierID,
			supplierClientID: supplier.entityID
		})
	}

	const handleCloseModal = () => {
		setModalSupplier(prev => {
			return {
				...prev,
				show: false
			}
		})
	}

	return (
		<section className="newRequest">
			<table className="newRequestTable">
				<thead>
					<tr>
						<th style={props.mainStyle}>
							<span>Name</span>
							<img className="sort" onClick={() => sortTable(order, "Name")} src={sortIcon} alt="Prodensa supplier" />
						</th>
						<th style={props.mainStyle}>
							<span>Industry</span>
							<img className="sort" onClick={() => sortTable(order, "Industry")} src={sortIcon} alt="Prodensa supplier" />
						</th>
						<th style={props.mainStyle}>
							<span>Location</span>
							<img className="sort" onClick={() => sortTable(order, "Location")} src={sortIcon} alt="Prodensa supplier" />
						</th>
						<th style={props.mainStyle}>
							<span>Date</span>
							<img className="sort" onClick={() => sortTable(order, "Date")} src={sortIcon} alt="Prodensa supplier" />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map((supplier, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span onClick={() => handleModalSupplier(supplier)}>
										{supplier.companyName}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{supplier.industries.map((industry, index) => {
										return supplier.industries.length > index + 1 ? industry + ", " : industry
									})}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{supplier.location}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{moment(supplier.createdAt).format("DD MMMM YYYY")}</span>
									<img onClick={() => handleModalSupplier(supplier)} className="reviewed" src={view} alt="Prodensa Supplier" />
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none newRequestList">
				{currentItems.map((supplier, index) => (
					<div key={index} className="newRequestRow" onClick={() => handleModalSupplier(supplier)}>
						<h5 className="newRequestName">{supplier.companyName}</h5>
						<div className="newRequestInformation">
							<span className="newRequestValue">{supplier.industries.map((industry, index) => {
								return supplier.industries.length > index + 1 ? industry + ", " : industry
							})}</span>
							<span className="newRequestTitle">Industry</span>
							<span className="newRequestValue">{supplier.location}</span>
							<span className="newRequestTitle">Location</span>
							<span className="newRequestValue">{moment(supplier.createdAt).format("DD MMMM YYYY")}</span>
							<span className="newRequestTitle">Date</span>
						</div>
					</div>
				))}
			</div>

			{modalSupplier.supplierID && modalSupplier.supplierClientID && 
				<NewRequestModal 
					show={modalSupplier.show} 
					userLogged={userLogged} 
					supplierID={modalSupplier.supplierID} 
					handleClose={() => handleCloseModal()} 
					supplierClientID={modalSupplier.supplierClientID} 
				/> 
			}

			<div className="addAndPagination">
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {suppliersNewRequest.length}</span>
		</section>
	)
}