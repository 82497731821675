import "../../../assets/css/tabs/documentInformationModal.css"

import close from "../../../assets/images/close.svg"
import info from "../../../assets/images/info.svg"

import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment"
import React, { useEffect, useRef, useState } from "react";
import Axios from "axios"
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";

export default function DocumentInformationModal(props) {
	// VARIABLE TO MANAGE USER LOGGED IN
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged

	// VARIABLES RECIEVED FROM PROPS
	const { show, onHide, documentData, changeDocumentInformation, supplier, setSupplier, supplierRfc, saveNewOnceDocument } = props

	// VARIABLES TO CONTROL THE DOCUMENT DATA
	const [document, setDocument] = useState(documentData)

	// VARIABLE TO CONTROL THE DOCUMENT COMMENT
	const [docComment, setDocComment] = useState(null)

	const [sendingEmail, setSendingEmail] = useState(false)

	const [controlReviewRequested, setControlReviewRequested] = useState(false)

	// VARIABLE TO CONTROL THE DOCUMENT INPUT
	const docInputRef = useRef(null)

	useEffect(() => {
		setDocument(documentData)
		setDocComment(documentData.documentData.comment)
	}, [documentData])

	// FUNCTION TO CHANGE THE DOCUMENT
	const handleDocumentChange = (_id, target) => {
		if (target.files[0].type === "application/pdf") {
			if (!document.newOnceDocument) {
				changeDocumentInformation(_id, "document", target.files[0], document.clientDocument)
			} else {
				saveNewOnceDocument(document.documentData, target.files[0], document.clientDocument)
			}

			onHide()
		} else {
			target.value = null
			Swal.fire({
				icon: 'error',
				text: 'The file is not a PDF format.',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		}
	}

	// FUNCTION TO CHANGE PROPERTY OF THE NEW ONCE DOCUMENT
	const handleChangeTemporalDocument = (property, newValue) => {
		setDocument(prevState => {
			return {
				...prevState,
				documentData: {
					...prevState.documentData,
					[property]: newValue
				}
			}
		})
	}

	function applyRequestReview () {
		setSendingEmail(true)
				
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		const data = {
			documentId: document.documentData._id,
			username: userLogged?.username,
			clientDocument: document?.clientDocument
		}

		Axios.patch(process.env.REACT_APP_URI_SUPPLIER_REVIEW_REQUESTED + supplier._id, data).then(res => {
			setSendingEmail(false)

			if (res.data) {
				setSupplier(res.data)

				setDocument(prevState => {
					return {
						...prevState,
						documentData: {
							...prevState.documentData,
							reviewRequest: true
						}
					}
				})
			}

			Swal.close()
		}).catch(err => {
			setSendingEmail(false)
			
			console.log(err)

			Swal.fire({
				icon: 'error',
				text: err.response.data.errorMessage,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}
	
	const handleRequestReview = () => {
		if (document.documentData.reviewRequest) return
		
		const isClientReadyForReview = supplier.clients.some(client => client.status === "Ready for review")

		const text = document.clientDocument || !isClientReadyForReview ? "Doing this will sent a notification to the supplier" :
		"Doing this will change the status for all clients to \"Review requested\" only for clients in \"Ready for review\" status"
		
		Swal.fire({
			title: "Are you sure you want to request the review of this doc?",
			text,
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Yes, request review",
		}).then((result) => {
			if (result.isConfirmed) {
				applyRequestReview()
			}
		});
	}

	const handleDeleteDoc = async () => {
		const response = await changeDocumentInformation(document.documentData._id, "document", "", document.clientDocument)

		if (response) {
			setDocument(prevState => {
				return {
					...prevState,
					documentData: {
						...prevState.documentData,
						document: ""
					}
				}
			})
		}
	}

	const handleCommentOnBlur = async (e) => {
		if (docComment === document.documentData.comment) {
			setControlReviewRequested(false)

			return
		} 
		
		if (!document.newOnceDocument) {
			const response = await changeDocumentInformation(document.documentData._id, "comment", e.target.value, document.clientDocument)

			if (response) {
				setDocument(prevState => {
					return {
						...prevState,
						documentData: {
							...prevState.documentData,
							comment: docComment
						}
					}
				})
				
				setControlReviewRequested(false)
			}

			return
		}

		handleChangeTemporalDocument("comment", e.target.value)

		setControlReviewRequested(false)
	}

	const showReviewRequestedButton = document.documentData._id && !document.documentData.compliant && (userLogged.rol !== "Supplier" || document.documentData.reviewRequest)
	
	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			centered
			className={`documentInformationModal ${props.darkLightMode ? "darkMode" : null}`}
		>
			<Modal.Body>
				<img className="closeModal" src={close} alt="Cattenna Platform" onClick={onHide} />

				<div className="documentHeader">
					{document.documentReference.documentDisclaimer &&
						<OverlayTrigger
							overlay={
								<Tooltip id={`tooltipDocumentInformation`} style={{ position: "fixed" }}>
									{document.documentReference.documentDisclaimer}
								</Tooltip>
							}>
							<img className="informationIcon" src={info} alt="Cattenna Platform" />
						</OverlayTrigger>
					}

					<h5 className="title">{document.documentReference.documentName}</h5>

					{showReviewRequestedButton && 
						<button className={`btnBgBlueTextGradient requestReview ${document.documentData.reviewRequest ? "inReview" : null}`} onClick={handleRequestReview} disabled={sendingEmail || controlReviewRequested}>
							{document.documentData.reviewRequest ? "Request review (replace doc)" : "Request review"}
						</button>
					}

					{document.documentReference.notApplicable && 
						<div className={`toggleContainer ${document.documentData.noActions ? "active" : ""}`}
							onClick={() => {
								!document.newOnceDocument
									? changeDocumentInformation(document.documentData._id, "noActions", !document.documentData.noActions, document.clientDocument)
									: handleChangeTemporalDocument("noActions", !document.documentData.noActions)
							}}>
							<div className="toggleIndicator"></div>
						</div>
					}
				</div>

				<div className="documentInformation">
					<div className="dataContainer">
						<span className="value">{document.documentData.yearCreatedAt}</span>
						<span className="title">Year</span>
					</div>
					<div className="dataContainer">
						<span className="value">
							{document.documentData.periodicity > 0
								? `${document.documentData.periodicity} ${document.documentData.periodicity > 1 ? "Months" : "Month"}`
								: document.documentData.dueDate}</span>
						<span className="title">Periodicity</span>
					</div>
					<div className="dataContainer">
						<span className="value">{moment(`${document.documentData.monthCreatedAt}/01/1900`).format("MMMM")}</span>
						<span className="title">Month</span>
					</div>
					<div className="dataContainer">
						<span className="value">{document.documentData.uploadedAt ? moment(document.documentData.uploadedAt).format("MMMM DD YYYY") : "Not uploaded"}</span>
						<span className="title">Date uploaded</span>
					</div>
					<div className="inputContainer">
						<span className="label">Document <bg-grey>Upload (5mb max)</bg-grey></span>
						<div className="buttonContainer">
							<input id="documentInput" className="d-none" type="file" accept="application/pdf" ref={docInputRef}
								onChange={(e) => handleDocumentChange(document.documentData._id, e.target)} />
							<button className={`documentSelector ${!document.documentData.compliant ? "" : "disabled"}`} onClick={() => !document.documentData.compliant ? docInputRef.current.click() : undefined}>
								{!document.documentData.document?.name && document.documentData.document ? document.documentData.document : "Select file"}
							</button>
							{document.documentData.document &&
								<React.Fragment>
									<button className="downloadButton"
										onClick={() => window.open(process.env.REACT_APP_URI_UPLOADS + supplierRfc + "/" + document.documentData.document)} />

									{!document.documentData.compliant && 
										<button className="deleteButton"
										onClick={handleDeleteDoc} 
										/>
									}
								</React.Fragment>
							}
						</div>
					</div>
					<div className="inputContainer">
						<span className="label">Compliance</span>
						{userLogged.rol !== "Supplier"
							? <Dropdown>
								<Dropdown.Toggle className={document.documentData.compliant ? "successAlone" : "failedAlone"}>
									{document.documentData.compliant ? "Compliant" : "Non-Compliant"}
								</Dropdown.Toggle>

								<Dropdown.Menu>

									<Dropdown.Item onClick={() => {
										!document.newOnceDocument
											? changeDocumentInformation(document.documentData._id, "compliant", true, document.clientDocument)
											: handleChangeTemporalDocument("compliant", true)
									}}>Compliant</Dropdown.Item>
									<Dropdown.Item onClick={() => {
										!document.newOnceDocument
											? changeDocumentInformation(document.documentData._id, "compliant", false, document.clientDocument)
											: handleChangeTemporalDocument("compliant", false)
									}}>Non-Compliant</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							: <span className={`compliancy ${document.documentData.compliant ? "successAlone" : "failedAlone"}`}>
								{document.documentData.compliant ? "Compliant" : "Non-Compliant"}
							</span>}
					</div>
					<div className="inputContainer">
						<span className="label">Comments</span>
						<textarea rows={4} value={docComment ?? ""} onChange={(e) => setDocComment(e.target.value)}
							disabled={userLogged.rol === "Supplier" ? true : false}
							onFocus={() => setControlReviewRequested(true)}
							onBlur={handleCommentOnBlur} 
							/>
					</div>
				</div>
			</Modal.Body>
		</Modal >
	)
}