import { v4 as uuidv4 } from "uuid";
import person from "../../../assets/images/person.svg"
import typeIcon from "../../../assets/images/breaking_news.svg"

export function reducer(state, action) {
    const isHistoric = state.showHistoric ? state.originalAlerts : state.originalAlerts.filter(item => !item?.completedBy)
    
    switch (action?.type) {
        case ACTIONS.setOpenFilter:
            const updateOpenFilter = state.openFilter === action?.payload ? null : action?.payload
            
            return {
                ...state,
                openFilter: updateOpenFilter
            }

        case ACTIONS.setActiveFilter:
            const updatedActiveFilters = state.activeFilters.includes(action?.payload) ?
                                        state.activeFilters :
                                        [...state.activeFilters, action?.payload]

            const filteredData = isHistoric?.filter(item => {
                return updatedActiveFilters.every(activeFilter => {
                    return state.filtersOptions?.[activeFilter].includes(item?.[PROPERTIES?.[activeFilter]])
                })
            })
            
            return {
                ...state,
                currentPage: 1,
                alerts: filteredData,
                activeFilters: updatedActiveFilters
            }

        case ACTIONS.setClearFilter:
            const newActiveFilters = state.activeFilters.filter(item => item !== action?.payload)

            const newFilteredData = isHistoric?.filter(item => {
                return newActiveFilters.every(activeFilter => {
                    return state.filtersOptions?.[activeFilter].includes(item?.[PROPERTIES?.[activeFilter]])
                })
            })

            const finalAlerts = newActiveFilters.length === 0 ? isHistoric : newFilteredData

            return {
                ...state,
                currentPage: 1,
                filtersOptions: {
                    ...state.filtersOptions,
                    [action.payload]: []
                },
                activeFilters: newActiveFilters,
                alerts: finalAlerts
            }

        case ACTIONS.setFilterOptions:
            const prevFilterOptions = { ...state.filtersOptions }
            const filterProperty = action?.payload.property
            const filterOption = action?.payload.value

            const updatedOptionsList = prevFilterOptions?.[filterProperty].includes(filterOption) ?
                                        prevFilterOptions?.[filterProperty].filter(item => item !== filterOption) :
                                        [...prevFilterOptions[filterProperty], filterOption]

            return {
                ...state,
                filtersOptions: {
                    ...prevFilterOptions,
                    [filterProperty]: updatedOptionsList
                }
            }

        case ACTIONS.handleSort:
            const sortBy = action?.payload
            const directionOfSort = state.sortBy !== sortBy ? "ASC" : state.sortOrder === "ASC" ? "DSC" : "ASC" 
            
            if (sortBy === PROPERTIES.createdAt) {
                const itemsSorted = state.alerts.sort((a, b) => {
                    const da = new Date(a.createdAt)
                    const db = new Date(b.createdAt)
                    
                    return directionOfSort === "ASC" ? da - db : db - da
                })

                return {
                    ...state,
                    sortOrder: directionOfSort,
                    sortBy,
                    alerts: itemsSorted
                }
            }

            const itemsSorted = state.alerts.sort((a, b) => {
                const xa = a[sortBy]?.toLowerCase()
                const xb = b[sortBy]?.toLowerCase()
                
                return directionOfSort === "ASC" ? xa?.localeCompare(xb) : xb?.localeCompare(xa)
            })

            return {
                ...state,
                sortOrder: directionOfSort,
                sortBy,
                alerts: itemsSorted
            }

        case ACTIONS.handleGlobalSearch:
            const searchValue = action?.payload

            if (searchValue === "") {
                return {
                    ...state,
                    currentPage: 1,
                    alerts: isHistoric
                }
            }

            const searchFiltered = isHistoric.filter(item => item?.companyName?.toLowerCase().includes(searchValue?.toLowerCase()))
            
            const clearFiltersOptions = FILTERS_MENU.reduce((acc, curr) => {
                acc[curr.accessKey] = []

                return acc
            }, {})
            
            return {
                ...state,
                currentPage: 1,
                activeFilters: [],
                filtersOptions: clearFiltersOptions,
                alerts: searchFiltered
            }

        case ACTIONS.handleCurrentPage:
            const currentPage = action?.payload

            return {
                ...state,
                currentPage
            }

        case ACTIONS.handleActionCompleted:
            const originalData = action?.payload.alerts
            const globalSearchValue = action?.payload.searchValue

            const sortedData = state.sortBy === PROPERTIES.createdAt ? 
                                originalData.sort((a, b) => {
                                    const da = new Date(a.createdAt)
                                    const db = new Date(b.createdAt)
                                    
                                    return state.sortOrder === "ASC" ? da - db : db - da
                                })
                                :
                                originalData.sort((a, b) => {
                                    const xa = a[state.sortBy]?.toLowerCase()
                                    const xb = b[state.sortBy]?.toLowerCase()
                                    
                                    return state.sortOrder === "ASC" ? xa?.localeCompare(xb) : xb?.localeCompare(xa)
                                })

            const dataFiltered = state.activeFilters.length === 0 ? sortedData :
                                sortedData.filter(item => {
                                    return state.activeFilters.every(activeFilter => {
                                        return state.filtersOptions?.[activeFilter].includes(item?.[PROPERTIES?.[activeFilter]])
                                    })
                                })

            const dataFilteredWithSearch = globalSearchValue === "" ? dataFiltered :
            dataFiltered.filter(item => item?.companyName?.toLowerCase().includes(globalSearchValue?.toLowerCase()))

            const dataFilteredHistoric = state.showHistoric ? dataFilteredWithSearch : dataFilteredWithSearch.filter(item => !item.completedBy)


            return {
                ...state,
                originalAlerts: originalData,
                alerts: dataFilteredHistoric
            }

        case ACTIONS.handleShowHistoric:
            const newIsHistoric = !state.showHistoric ? state.originalAlerts : state.originalAlerts.filter(item => !item?.completedBy)
            const checkSearchValue = action?.payload
            
            const historicFilter = state.activeFilters.length === 0 ? 
                (checkSearchValue !== "" ? 
                    newIsHistoric.filter(item => item?.companyName?.toLowerCase().includes(checkSearchValue?.toLowerCase()))
                    :
                    newIsHistoric
                ) 
            :
                newIsHistoric?.filter(item => {
                    return state.activeFilters.every(activeFilter => {
                        return state.filtersOptions?.[activeFilter].includes(item?.[PROPERTIES?.[activeFilter]])
                    })
                })

            return {
                ...state,
                showHistoric: !state.showHistoric,
                alerts: historicFilter
            }

        case ACTIONS.handleMoreOptions:
            const alertID = action?.payload

            const moreOptionsOpen = alertID === state?.moreOptionsOpen ? null : alertID

            return {
                ...state,
                moreOptionsOpen
            }
            
        default:
            return state;
    }
}

export const PROPERTIES = {
    supplier: "companyName",
    typeOfAlert: "typeOfAction",
    client: "clientName",
    createdAt: "createdAt",
    completedBy: "completedBy"
}

const ROUTE_FOR_ALERT = {
    "Document uploaded": "/home/suppliers/supplierProfile/",
    "Ready for review": "/home/requests/reviewForAuth/",
    "New request": "/home/requests?companyName="
}

export const getRouteForAlert = (alert) => {
    const property = alert?.typeOfAction === "Document uploaded" && !alert?.isSupplierActive ? "Ready for review" : alert?.typeOfAction

    const preRoute = ROUTE_FOR_ALERT[property]

    return alert?.typeOfAction === "New request" ? preRoute + alert?.companyName : preRoute + alert?.supplierID + `?fromAlerts=${alert?.typeOfAction === "Document uploaded" ? "doc" : "review"}`
}

export const ACTIONS = {
    setOpenFilter: "SET_OPEN_FILTER",
    setActiveFilter: "SET_ACTIVE_FILTER",
    setClearFilter: "SET_CLEAR_FILTER",
    setFilterOptions: "SET_FILTER_OPTIONS",
    handleSort: "HANDLE_SORT",
    handleGlobalSearch: "HANDLE_GLOBAL_SEARCH",
    handleCurrentPage: "HANDLE_CURRENT_PAGE",
    handleActionCompleted: "HANDLE_ACTION_COMPLETED",
    handleShowHistoric: "HANDLE_SHOW_HISTORIC",
    handleMoreOptions: "HANDLE_MORE_OPTIONS"
}

export const FILTERS_MENU = [
    {
        id: uuidv4(),
        title: "Supplier",
        accessKey: "supplier",
        icon: person
    },
    {
        id: uuidv4(),
        title: "Client",
        accessKey: "client",
        icon: person,
    },
    {
        id: uuidv4(),
        title: "Type of Alert",
        accessKey: "typeOfAlert",
        icon: typeIcon,
        items: ["Document uploaded", "New request", "Ready for review"]
    }
]

export const createSupplierFilter = (data) => {
    const filterSupplier = new Set(data?.map(supplier => supplier.companyName).filter(item => item))

    return Array.from(filterSupplier).sort()
}

export const createClientFilter = (data) => {
    const filterClient = new Set(data?.map(alert => alert?.clientName).filter(item => item))
    
    return Array.from(filterClient).sort()
}